import React from 'react';

import FormProvider from '../Form/context';
import CheckoutProvider from './context';

import Row from '../Container/row';
import Col from '../Container/column';

import loadComponents from '../Loadable';

const Form = loadComponents('checkout-form');
const Summary = loadComponents('checkout-summary');
const Information = loadComponents('checkout-information');
// const Total = loadComponents('checkout-totals');
const Alert = loadComponents('alert');

export default function CheckoutLayout({
  payment,
  togglePayment,
  currency,
  total,
  initial,
  items,
  orderId,
  displayError,
  displaySuccess,
  appointment,
  children,
}) {
  const formProps = {
    payment,
    togglePayment,
    currency,
    total,
    orderId,
    items,
  };

  const informationProps = {
    initial,
    orderId,
  };

  const checkoutProps = {
    initial,
    orderId,
    payment,
    currency,
    total,
    items,
    displayError,
    displaySuccess,
  };
  return (
    <>
      <Alert info style={{ marginBottom: `45px` }}>
        Thank you. Your information has been submitted.{' '}
        {appointment
          ? `Your preferred appointment date is: ${appointment}. We'll be in touch to confirm this date within the next 24 hours once you payment has been processed`
          : `If you require an appointment for your visa we will book this with you once your application has been verified and payment is processed.`}
      </Alert>
      <Row justify="space-between">
        <Col xl={{ width: '25%' }}>
          <Summary items={items} currency={currency} />
        </Col>
        <Col className="checkoutFormOuter" xl={{ width: '65%' }}>
          {children}
          {initial && <Information {...informationProps} />}
          <FormProvider>
            <CheckoutProvider {...checkoutProps}>
              <Form {...formProps} />
            </CheckoutProvider>
          </FormProvider>
        </Col>
      </Row>
    </>
  );
}
